import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';
import { Row } from 'react-bootstrap';
import HelperText from '../input/helperText';
import { colors } from '../../constants/colors';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { swalError } from "../../components/notification/swal";
import SubText from '../input/subText';

const MAX_FILE_SIZE_MB = Number(process.env.REACT_APP_MAX_FILE_SIZE) || 30; // กำหนดขนาดไฟล์สูงสุด (MB)
const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024; // แปลง MB เป็น Bytes

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


export const UploadFileContainer = styled(Row)(() => ({
  border: `1px dashed ${colors.blue}`,
  margin: '0px 3px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center'
}))

type UploadImageProps = {
  onUpload?: (event: any) => void
  helperText?: any;
  loading?: boolean | false
  ref?: any
}

export default function UploadImage(props: UploadImageProps) {
  return (
    <>
    <SubText label={`อัปโหลดได้เฉพาะไฟล์ขนาดไม่เกิน ${MAX_FILE_SIZE_MB}MB`} />
      <UploadFileContainer>
        <LoadingButton
          component="label"
          role={undefined}
          variant="text"
          startIcon={<CloudUploadOutlinedIcon />}
          tabIndex={-1}
          loading={props.loading}
          loadingIndicator="กำลังอัพโหลด..."
          // loadingPosition="start"
        >
          อัพโหลด

          {/* <VisuallyHiddenInput 
            type="file" accept="image/png, image/jpeg , image/jpg ,application/pdf" onChange={(event: any) => { props.onUpload && props.onUpload(event) }} /> */}
          <VisuallyHiddenInput
            type="file"
            accept="image/png, image/jpeg , image/jpg ,application/pdf"
            onChange={(event: any) => {
              const file = event?.target?.files[0];
              if (file) {
                if (file.size > MAX_FILE_SIZE) {
                  swalError(`ไฟล์ต้องมีขนาดไม่เกิน ${MAX_FILE_SIZE_MB}MB`);
                  event.target.value = ""; // ล้างค่า input
                  return;
                }
                props.onUpload && props.onUpload(event);
              }
            }}
          />
        </LoadingButton>
      </UploadFileContainer>

      {props.helperText && (
        <div className="pt-2">
          <HelperText label={props.helperText} />
        </div>
      )}
    </>
  );
}
