import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined'
import { styled } from '@mui/material/styles'
import { colors } from '../../constants/colors'

const CustomSubText = styled('div')(() => ({
  display: 'flex',
  svg: {
    marginTop: 4
  },
  path: {
    fill: colors.dark
  },
  span: {
    color: colors.dark,
    fontSize: 12,
    paddingLeft: 4
  }
}))

type SubTextProps = {
  label: string
  props?: any
}
export default function SubText(props: SubTextProps) {
  return (
    <CustomSubText {...props.props}>
      <ErrorOutlinedIcon sx={{ fontSize: 13 }} /> <span>{props.label}</span>
    </CustomSubText>
  )
}
