import { Box } from '@mui/material';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PartnerApi from '../../api-partner/partner/partner.api';
import AuthenApi from '../../api/authen/authen.api';
import OptionsApi from '../../api/option/option.api';
import Loading from '../../components/loading/loading';
import { swalError, swalCustom } from '../../components/notification/swal';
import { colors } from '../../constants/colors';
import { defaultDataRegister } from './default';
import LayoutCareer from './layout-career/layout-career';
import LayoutComplete from './layout-complete/layout-complete';
import LayoutContact from './layout-contact/layout-contact';
import LayoutPersonal from './layout-personal/layout-personal';
import LayoutProduct from './layout-product/layout-product';
import LayoutRegister from './layout-register/layout-register';

const steps = [
  'ลงทะเบียน',
  'สินค้า',
  'ส่วนตัว',
  'อาชีพ',
  'ผู้ติดต่อ',
];
interface WindowWithAdTech extends Window {
  adTechTrackerInit?: (key: string) => void;
  tmfire?: (publicKey: string, eventData: object) => void;
}

export default function Register() {
  const { pathname } = useLocation();
  const [activeStep, setActiveStep] = useState<number>(0)
  const [data, setData] = useState<any>(defaultDataRegister)
  const [downPayment, setDownPayment] = useState<any>()
  const [checkedItems, setCheckedItems] = useState<any>()
  
  const [loading, setLoading] = useState(false)
  const [optionMaster, setOptionMaster] = useState<any[]>([])
  
  // check version first
  const checkVersionAndReload = async () => {
    try {
      const webVersion = process.env.REACT_APP_WEB_VERSION; // เวอร์ชันจาก env
      const response = await AuthenApi.checkWebVersion({webVersion})
      return { response: response?.data}
    } catch (error) {
      return { response: false}
    }
  };

  const fetchVersionAndHandleUpdate = async () => {
    const responeCheck =  await checkVersionAndReload();
    if (!responeCheck?.response) {
      swalCustom(
        "ระบบมีการอัปเดตเวอร์ชั่น",
        "กรุณากดตกลงเพื่อดำเนินการอัปเดตเวอร์ชันใหม่",
        (isConfirmed: boolean) => {
          if (isConfirmed) {
            window.location.reload();
          }
        },
        "warning",
        {
          showCloseButton: false,
          confirmButton: {
            showConfirmButton: true,
            confirmButtonText: "ตกลง",
          },
          cancelButton: {
            showCancelButton: false,
          },
        }
      );
    }
  };
  
  useEffect(() => {
    fetchVersionAndHandleUpdate();
  }, []);

  const onNextStep = (index: number) => {
    setActiveStep(index)
  }

  const onBackStep = (index: number) => {
    setActiveStep(index)
  }

  useEffect(() => {
    console.log(data)
  }, [data]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  useEffect(() => {
    setData(defaultDataRegister)
  }, [defaultDataRegister]);

  const loadAddress = useCallback(() => {
    OptionsApi.address().then((res) => {
      if (res.status === 200) {
        setOptionMaster(res.data.address)
      }
    })
  }, [])

  useEffect(() => {
    loadAddress()
  }, [loadAddress]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = process.env.REACT_APP_API_ADTECH || '';
    script.async = true;
    const customWindow = window as WindowWithAdTech;
    script.onload = () => {
      if (customWindow.adTechTrackerInit) {
        customWindow.adTechTrackerInit(process.env.REACT_APP_API_ADTECH_KEY || '');
      } else {
        console.error("adTechTrackerInit function is not available");
      }
    };
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);


  const onSubmit = async () => {
    if (data) {
      setLoading(true)
      if (data.job?.studentImages?.length > 0) {
        data.job.studentImages.forEach((item: any) => {
          delete item.name;
          delete item.img;
        });
      }
      if (data.job?.transcriptOrTermFees?.length > 0) {
        data.job.transcriptOrTermFees.forEach((item: any) => {
          delete item.name;
          delete item.img;
        });
      }
      if (data.profile?.imeiImages?.images?.length > 0) {
        data.profile?.imeiImages?.images.forEach((item: any) => {
          delete item.name;
          delete item.img;
        });
      }
      if (data.profile?.iphoneStorage?.images?.length > 0) {
        data.profile?.iphoneStorage?.images.forEach((item: any) => {
          delete item.name;
          delete item.img;
        });
      }
      if (data.statement?.images?.length > 0) {
        data.statement.images.forEach((item: any) => {
          delete item.name;
          delete item.img;
        });
      }
      if (data.proveWork?.length > 0) {
        data.proveWork.forEach((item: any) => {
          delete item.name;
          delete item.img;
        });
      }
      if (data.riderAppication?.length > 0) {
        data.riderAppication.forEach((item: any) => {
          delete item.name;
          delete item.img;
        });

      }
      if (data.governmentOfficial?.length > 0) {
        data.governmentOfficial.forEach((item: any) => {
          delete item.name;
          delete item.img;
        });
      }
      if (data.proveOwnBusinessImage?.length > 0) {
        data.proveOwnBusinessImage.forEach((item: any) => {
          delete item.name;
          delete item.img;
        });
      }
      if (data.salesChannelsImage?.images?.length > 0) {
        data.salesChannelsImage?.images?.forEach((item: any) => {
          delete item.name;
          delete item.img;
        });
      }

      //เพิ่ม data register_web_version ทุก record ตอน save
      data['register_web_version'] = process.env.REACT_APP_WEB_VERSION

      const customWindow = window as WindowWithAdTech;
      const publicKey = process.env.REACT_APP_API_ADTECH_KEY || '';
      const eventData = {
        data: {
          event_name: "Lead",
          custom_data: {
            currency: "thb",
            value: 10
          }
        }
      };

      if (customWindow.tmfire) {
        customWindow.tmfire(publicKey, eventData);
      }
      const res = await AuthenApi.registerBeforeVerify(data).then(async (e) => {
        setLoading(false)
        window.scrollTo(0, 0);
        setActiveStep(5)
        console.log(data?.chatcone?.social_id)
        return e.data
      }
      ).catch((e: any) => {
        setLoading(false)
        if (e.response.status == 500) {
          return swalError('ระบบมีปัญหากรุณาลองใหม่อีกครั้ง')
        }
      })
      if (data?.chatcone?.social_id) {
        const { id } = res
        const payload = {
          chatcone: data?.chatcone,
          process_type: 'REGISTER',
          register_id: id,
          ref_id: id,
          route_from: `${process.env.REACT_APP_URL}${pathname}`
        }
        await PartnerApi.sendChatconeByProcess(payload)
      }
      setLoading(false)
    }
  }
  return (

    <Box sx={{ width: '100%', paddingTop: 20 }}>
      <Loading show={loading} type="fullLoading" />
      <div>
        <Stepper activeStep={activeStep} alternativeLabel
          sx={{
            "& .MuiStepLabel-root .Mui-completed": {
              color: colors.themeMainColor
            },
            "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
              color: colors.black
            },
          }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          {activeStep === 0 && (
            <LayoutRegister onClickNext={() => { onNextStep(1) }} data={data} onChange={setData} optionMaster={optionMaster} />
          )}
          {activeStep === 1 && (
            <LayoutProduct onClickNext={() => { onNextStep(2) }} data={data} onChange={setData} downPaymentVal={setDownPayment} checkedItemsVal={setCheckedItems} checkedItems={checkedItems} />
          )}
          {activeStep === 2 && (
            <LayoutPersonal onClickNext={() => { onNextStep(3) }} onClickBack={() => { onBackStep(1) }} data={data} onChange={setData} optionMaster={optionMaster} />
          )}
          {activeStep === 3 && (
            <LayoutCareer onClickNext={() => { onNextStep(4) }} onClickBack={() => { onBackStep(2) }} data={data} onChange={setData} downPayment={downPayment} />
          )}
          {activeStep === 4 && (
            <LayoutContact onSubmit={() => { onSubmit() }} onClickBack={() => { onBackStep(3) }} data={data} onChange={setData} />
          )}
          {activeStep === 5 && (
            <LayoutComplete profile={data.profile} type={data.type} chatcone={data.chatcone} />
          )}
        </div>
      </div>

    </Box>

  );
}
