import BaseAPI from '../api'

const path = 'app/authen'

interface register {
  type: string
  prefix: string
  firstName: string
  lastName: string
  phoneNumber: string
  chatcone?: {
    social_id?: string | null
    token?: string | null
    channel_id?: string | null
  } | null,
  amplitudeId?: string
  walkin?: boolean
}
interface cardLocationInterface {
  type: string;
}



export default class AuthenApi extends BaseAPI {

  static registerCustomer(payload: register): Promise<any> {
    return this.api.post(`${path}/register-customer`, payload).then((res) => res)
  }

  static registerBeforeVerify(payload: any): Promise<any> {
    return this.api.post(`${path}/register-before-verify`, payload).then((res) => res)
  }

  static cardLocation(payload: cardLocationInterface): Promise<any> {
    return this.api.post(`/admin/authen/branchRegis/options`, payload).then((res) => res)
  }

  static checkWebVersion(param: any): Promise<any> {
    return this.api.get(`${path}/check-web-version`, { params: { ...param } }).then((res) => res)
  }

}
